
























import { Component, Vue, Prop } from "vue-property-decorator";

import {} from "@/core/services";
import {} from "@/core/components";

import AppVue from "@/AppVue.vue";
import { ExportAllowanceModel, ExportService } from "../services/export.service";

export class Type {}

@Component({
  components: {},
})
export default class ExportSummaryCpt extends AppVue {
  model: ExportAllowanceModel = new ExportAllowanceModel();

  async created() {
    this.model = await ExportService.getAllowance();
  }

  get allowancePercentage() {
    return Math.round((this.model.used / this.model.allowance) * 100);
  }
}
