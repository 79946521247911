






















































































































// libraries
import AppVue from "@/AppVue.vue";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { cisDateTimeExportFilter } from "@/core/filters";

// services
import { IUIFilters, SearchService } from "@/core/services";
import {
  ExportConfigurationModel,
  ExportFieldModel,
  ExportService,
  TrackingExportConfigurationModel,
  VerifyExportModel,
} from "../services/export.service";
import { Field } from "@/core/models";

// components
import {} from "@/core/components";

@Component({})
export default class CreateExportCpt extends AppVue {
  @Prop({ default: false }) filters: IUIFilters;
  @Prop({ default: false }) trackingFilters: TrackingExportConfigurationModel;
  @Prop({ default: false }) selectedProjects: [];
  $refs: {
    treeProject: HTMLFormElement;
    treeCompany: HTMLFormElement;
  };
  results = 0;
  projectTab = true;
  companyTab = true;
  fields: ExportFieldModel[] = [];
  model = new ExportConfigurationModel();
  pfields: string[] = ["ProjectId", "ProjectHeading", "ProjectSiteCounty", "ProjectSiteCountry"];
  cfields: string[] = [];
  downloadLoading = false;
  searchLoading = false;
  searchPreview = new VerifyExportModel();
  exportAllData = true;
  defaultProps = {
    children: "children",
    label: "label",
  };
  @Watch("trackingFilters")
  onTrackingFiltersChanged() {
    this.trackingFilterUpdated(this.trackingFilters);
  }
  @Watch("filters")
  onFiltersChanged() {
    if (!!this.filters) {
      this.filterUpdated(this.filters);
    }
  }

  @Watch("exportAllData")
  onExportAllDataChanged() {
    if (this.exportAllData) {
      this.resetFields();
    }
    this.updateTree();
  }
  get isTracking() {
    return !!this.trackingFilters && !this.filters;
  }
  get treeCompanyFields() {
    return this.treeDataTransform("Company");
  }
  get treeProjectFields() {
    return this.treeDataTransform("Project");
  }
  get companyFields() {
    return this.fields.filter((x) => x.entity === "Company").sort((a, b) => a.order - b.order);
  }
  get projectFields() {
    return this.fields.filter((x) => x.entity === "Project").sort((a, b) => a.order - b.order);
  }
  treeDataTransform(type: string) {
    const f = this.fields.filter((x) => x.entity === type).sort((a, b) => a.order - b.order);
    const groupBy = (xs: any[], key: string) =>
      xs.reduce((rv, x) => {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    const grouped = groupBy(f, "category");
    const noCategoryMeansRoot = true;
    let roots: any[] = [];
    const res2 = Object.entries(grouped).map(([key, value]) => {
      const v = value as ExportFieldModel[];
      if (key === "" && noCategoryMeansRoot) {
        roots = v.map((y) => ({
          label: y.displayName,
          data: y.field,
          order: y.order,
        }));
        return null;
      }
      return {
        order: v[0].order,
        data: key,
        label: key || "Basic",
        children: v.map((y) => ({
          label: y.displayName,
          data: y.field,
          order: y.order,
        })),
      };
    }) as any[];
    const output = roots.concat(res2.filter((x) => x !== null)).sort((a, b) => {
      if (!!a.children) {
        a.children = a.children.sort((c: ExportFieldModel, d: ExportFieldModel) => c.order - d.order);
      }
      return a.order - b.order;
    });
    return output;
  }
  companyTreeCheck(node: any, satus: any) {
    this.cfields = this.$refs.treeCompany.getCheckedKeys(true);
  }
  projectTreeCheck(node: any, satus: any) {
    this.pfields = this.$refs.treeProject.getCheckedKeys(true);
  }
  queryUpdated() {
    this.searchLoading = true;
    this.$nextTick(() => {
      this.previewRequest();
      this.searchLoading = false;
    });
  }
  trackingFilterUpdated(filters: TrackingExportConfigurationModel) {
    this.model.OutputSettings = filters.outputSettings;
    this.$nextTick(() => {
      this.previewRequest();
      this.searchLoading = false;
    });
  }
  filterUpdated(filters: IUIFilters) {
    this.model.SearchModel.fields.length = 0;
    const results: Field[] = SearchService.buildProjectFilter(
      filters.trackingFilter,
      filters.geoRange,
      filters.value.min,
      filters.value.max,
      filters.stage,
      filters.categories,
      [
        filters.dateUpdated,
        filters.dateCreated,
        filters.tenderDeadlineDate,
        filters.finishDate,
        filters.projectStartDate,
        filters.applicationDate,
        filters.decisionDate,
      ],
      filters.counties,
      filters.isSelfBuild,
      filters.roles,
      filters.polyline,
      filters.fundingType,
      filters.constructionType,
      filters.planningAuthority,
      filters.materials,
      filters.floorArea,
      filters.numberOfParkingSpaces,
      filters.siteArea,
      filters.numberOfStoreys,
      filters.numberOfUnits,
      filters.applicationType,
      this.filters.isFramework,
      filters.appealStatusList,
      filters.publicTag,
    );
    results.forEach((x) => this.model.SearchModel.fields.push(x));
    this.model.SearchModel.freeText = filters.query;
    this.model.SearchModel.freeTextType = filters.queryType;
    this.$nextTick(() => {
      this.previewRequest();
      this.searchLoading = false;
    });
  }
  resetFields() {
    this.cfields = [...this.companyFields.map((f) => f.field)];
    this.pfields = [...this.projectFields.map((f) => f.field)];
  }

  toggleProjectFields() {
    if (this.pfields.length) {
      this.pfields = [];
    } else {
      this.pfields = [...this.projectFields.map((f) => f.field)];
    }
    this.updateTree();
  }

  toggleCompanyFields() {
    if (this.cfields.length) {
      this.cfields = [];
    } else {
      this.cfields = [...this.companyFields.map((f) => f.field)];
    }
    this.updateTree();
  }
  updateTree() {
    this.$nextTick(() => {
      this.$refs.treeCompany.setCheckedKeys(this.cfields, true);
      this.$refs.treeProject.setCheckedKeys(this.pfields, true);
    });
  }

  async created() {
    this.fields = await ExportService.getExportFields();
    this.resetFields();
    if (!!this.filters) {
      this.filterUpdated(this.filters);
    } else if (!!this.trackingFilters) {
      this.trackingFilterUpdated(this.trackingFilters);
    }
  }
  async previewRequest() {
    this.model.OutputSettings.ColumnList = [
      ...(this.companyTab ? this.cfields : []),
      ...(this.projectTab ? this.pfields : []),
    ];
    if (!!this.trackingFilters) {
      this.searchPreview = await ExportService.previewTrackingProjectExport({
        ...this.trackingFilters,
        outputSettings: this.model.OutputSettings,
      } as TrackingExportConfigurationModel);
    } else {
      // Check if a user has manually selected some projects to export
      this.model.SelectedProjects = this.selectedProjects ? this.selectedProjects : [];

      this.searchPreview = await ExportService.previewExport(this.model);
    }
  }
  async sendRequest() {
    this.model.OutputSettings.ColumnList = [
      ...(this.companyTab ? this.cfields : []),
      ...(this.projectTab ? this.pfields : []),
    ];

    try {
      this.$emit("status", "started");
      this.downloadLoading = true;
      let result: any;
      if (!!this.trackingFilters) {
        result = await ExportService.runTrackingProjectExport({
          ...this.trackingFilters,
          outputSettings: this.model.OutputSettings,
        } as TrackingExportConfigurationModel);
      } else {
        // Check if a user has manually selected some projects to export
        this.model.SelectedProjects = this.selectedProjects ? this.selectedProjects : [];

        result = await ExportService.runExport(this.model);
      }

      const url = window.URL.createObjectURL(new Blob([result]));
      const link = document.createElement("a");
      const dateString = cisDateTimeExportFilter(new Date().toString());
      let extension = "xls";
      if (result.type !== "application/xls") {
        extension = "pdf";
      }
      const filename = `Export ${dateString}.${extension}`;
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      this.$notify({
        title: "Download completed!",
        type: "success",
        message: `${filename} downloaded successfully`,
      });
    } finally {
      this.downloadLoading = false;
      this.$emit("status", "complete");
    }
  }

  async mounted() {}
}
