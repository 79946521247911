import { SearchModel, PagedCollection, UserWatchesResult, UserWatch } from "@/core/models";
import { HttpService, TagModel } from "@/core/services";
const routeRoot: string = "export";
export class ExportFieldModel {
    entity: string;
    category: string;
    field: string;
    displayName: string;
    order: number;
}
export class ExportFormatModel {
    ColumnList: string[];
    FileFormat: string;

    constructor() {
        this.FileFormat = "2";
    }
}
export class TrackingExportConfigurationModel {
    tags: number[];
    userId: number;
    untrackedOnly: boolean;
    outputSettings: ExportFormatModel;
    constructor() {
        this.tags = [];
        this.userId = 0;
        this.outputSettings = new ExportFormatModel();
    }
}
export class ExportConfigurationModel {
    SearchModel: SearchModel;
    OutputSettings: ExportFormatModel;
    SelectedProjects: number[];
    /**
     *
     */
    constructor() {
        this.SearchModel = new SearchModel();
        this.OutputSettings = new ExportFormatModel();
    }
}

export class VerifyExportModel {
    succeeded: boolean;
    totalRecords: number;
    recordsAllowed: number;
    remainingAllowance: number;
    errorMessage: string;
}

export class UserExportModel {
    userSearchExportId: number;
    userId: number;
    exportFileUrl: string;
    dateDeleted: Date;
    dateUpdated: Date;
    dateCreated: Date;
    dateCompleted: Date;
    deletedBy: number;
    updatedBy: number;
    exportConfiguration: ExportConfigurationModel;
    numberOfProjects: number;
    numberOfCompanies: number;
}

export class ExportAllowanceModel {
    used: number;
    allowance: number;
}

export const ExportService = {
    async list(pageNumber: number) {
        return await HttpService.getData<PagedCollection<UserExportModel>>(`${routeRoot}/list/${pageNumber}`);
    },
    async filterTrackedProjects(userId: number, tags: number[], page: number, filterUntrackedOnly: boolean) {
        let querystring = "";
        let tagq = "";
        let untrq = "";
        if (tags.length > 0) {
            tagq = tags.map(x => `tags=${x}`).join("&");
        }
        if (filterUntrackedOnly) {
            untrq = "currentlyUntracked=true";
        }
        if (tagq && untrq) {
            querystring = `?${tagq}&${untrq}`;
        } else if (tagq) {
            querystring = `?${tagq}`;
        } else if (untrq) {
            querystring = `?${untrq}`;
        }
        return await HttpService.getData<PagedCollection<UserWatch>>(`${routeRoot}/fav/project/user/${userId}/page/${page}${querystring}`);
    },
    async filterTrackedCompanies(userId: number, tags: number[], page: number, filterUntrackedOnly: boolean) {
        let querystring = "";
        let tagq = "";
        let untrq = "";
        if (tags.length > 0) {
            tagq = tags.map(x => `tags=${x}`).join("&");
        }
        if (filterUntrackedOnly) {
            untrq = "currentlyUntracked=true";
        }
        if (tagq && untrq) {
            querystring = `?${tagq}&${untrq}`;
        } else if (tagq) {
            querystring = `?${tagq}`;
        } else if (untrq) {
            querystring = `?${untrq}`;
        }

        return await HttpService.getData<PagedCollection<UserWatch>>(`${routeRoot}/fav/company/user/${userId}/page/${page}${querystring}`);
    },
    async getExportFields() {
        return await HttpService.getData<ExportFieldModel[]>(`${routeRoot}/fields`);
    },
    async previewExportFromSearch(searchId: number, config: ExportConfigurationModel) {
        return await HttpService.postData<VerifyExportModel>(`${routeRoot}/preview/search/${searchId}`, config);
    },
    async previewTrackingProjectExport(config: TrackingExportConfigurationModel) {
        const url = "export/fav/project/export/user";
        return await HttpService.postData<VerifyExportModel>(`${url}/${config.userId}/preview`, config);
    },
    async previewTrackingCompanyExport(config: TrackingExportConfigurationModel) {
        const url = "export/fav/company/export/user";
        return await HttpService.postData<VerifyExportModel>(`${url}/${config.userId}/preview`, config);
    },
    async previewExport(config: ExportConfigurationModel) {
        return await HttpService.postData<VerifyExportModel>(`${routeRoot}/preview`, config);
    },
    async previewCompanyExport(config: ExportConfigurationModel) {
        return await HttpService.postData<VerifyExportModel>(`${routeRoot}/company/preview`, config);
    },
    async runExportFromSearch(searchId: number, config: ExportConfigurationModel) {
        return await HttpService.postData<any>(`${routeRoot}/run/search/${searchId}`, config, {
            responseType: "blob",
        });
    },
    async runTrackingProjectExport(config: TrackingExportConfigurationModel) {
        const url = "export/fav/project/export/user";

        return await HttpService.postData<any>(`${url}/${config.userId}`, config, {
            responseType: "blob",
        });
    },
    async runTrackingCompanyExport(config: TrackingExportConfigurationModel) {
        const url = "export/fav/company/export/user";
        return await HttpService.postData<any>(`${url}/${config.userId}`, config, {
            responseType: "blob",
        });
    },
    async runExport(config: ExportConfigurationModel) {
        return await HttpService.postData<any>(`${routeRoot}/run`, config, {
            responseType: "blob",
        });
    },
    async runCompanyExport(config: ExportConfigurationModel) {
        return await HttpService.postData<any>(`${routeRoot}/company/run`, config, {
            responseType: "blob",
        });
    },
    async getExportFile(userSearchExportId: number) {
        return await HttpService.getData<any>(`${routeRoot}/${userSearchExportId}`, {
            responseType: "blob",
        });
    },
    async getAllowance() {
        return await HttpService.getData<ExportAllowanceModel>(`${routeRoot}/allowance`);
    },
};