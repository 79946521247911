










































































import { Component, Vue, Prop } from "vue-property-decorator";
import AppVue from "@/AppVue.vue";
import { SavedSearchService, SavedCompanySearchService } from "@/core/services";
import { SearchService } from "@/core/services";
import { SavedSearchModel, SearchModel } from "@/core/models";
import EventBus from "@/core/components/this.$eventHub.vue";
import { SearchFilterSummaryCpt } from "@/modules/search";

@Component({
  components: {
    SearchFilterSummaryCpt: () => Promise.resolve(SearchFilterSummaryCpt),
  },
})
export default class ExportSavedSearchesCpt extends AppVue {
  @Prop({ default: 5 }) max: number;

  loading = true;
  model: SavedSearchModel[] = [];
  companies: SavedSearchModel[] = [];

  mounted() {}

  created() {
    this.initializeModel().then(() => {
      this.getSearchModels().then(() => {
        this.loading = false;
      });
    });
  }

  runExport(searchModel: SearchModel) {
    this.$emit("RunExport", searchModel);
  }

  private async getSearchModels() {
    this.model.forEach(this.getSearchModel);
    this.companies.forEach(this.getSearchModel);
  }

  private async getSearchModel(savedSearchModel: SavedSearchModel, ix: number) {
    const searchModel = await SavedSearchService.getSingle(savedSearchModel.userAzureSearchID);
    if (savedSearchModel.searchType === 0) {
      this.model[ix].searchModel = searchModel;
    }
    if (savedSearchModel.searchType === 1) {
      this.companies[ix].searchModel = searchModel;
    }
  }

  private async initializeModel() {
    try {
      this.model = await SavedSearchService.list(this.max);
      this.companies = await SavedCompanySearchService.list(this.max);
    } finally {
    }
  }
}
