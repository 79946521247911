import L from "leaflet";

export const greenMarker = "#1fd916";
export const orange = "#fb6d13";
export const blueMarker = "#0097B7";
export const reverseMarkerStyle = () => `
  position: relative;
  transform: rotate(-45deg) scale(2) translateX(9px) translateY(6px);
`;
export const makeMarkerStyle = (color: string) => `
  background-color: ${color};
  width: 3.03rem;
  height: 3rem;
  display: block;
  left: -1.5rem;
  top: -1.5rem;
  position: relative;
  border-radius: 3rem 3rem 0;
  transform: rotate(45deg);
  border: 1px solid ${color}`;

export const makeScaleStyle = (scale: number) => `
    transform: scale(${scale});
  `;

const renderer = (marker: string, color: string = blueMarker) => `
<div class="a-marker" style="${makeScaleStyle(.6)}">
  <div style="${makeMarkerStyle(color)}">
    <div style="${reverseMarkerStyle()}">
      <i class="${marker}"></i>
    </div>
  </div>
</div>`;

export const IconFactory = {
  getCompanyIconMarker(className: string) {
    return L.divIcon({
      className,
      iconAnchor: [0, 24],
      popupAnchor: [0, -36],
      html: renderer(`${className}-white`, orange),
    });
  },
  getIconForclass(className: string) {
    return L.divIcon({
      className,
      iconAnchor: [0, 24],
      popupAnchor: [0, -36],
      html: renderer(`${className}-white`),
    });
  },
};

export const greenIcon = L.divIcon({
  className: "my-custom-pin",
  iconAnchor: [0, 24],
  popupAnchor: [0, -36],
  html: renderer(greenMarker),
});

export const orangeIcon = L.divIcon({
  className: "my-custom-pin",
  iconAnchor: [0, 24],
  popupAnchor: [0, -36],
  html: renderer(orange),
});

export const blueIcon = L.divIcon({
  className: "my-custom-pin",
  iconAnchor: [0, 24],
  popupAnchor: [0, -36],
  html: renderer(blueMarker),
});