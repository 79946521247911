

























































































import { Component, Vue } from "vue-property-decorator";
import { IUIFilters, SearchService } from "@/core/services";
import {} from "@/core/components";
import { Field } from "@/core/models";

import AppVue from "@/AppVue.vue";
import { SearchFilterBarCpt } from "../search";
import {
  ExportConfigurationModel,
  ExportFieldModel,
  ExportService,
  VerifyExportModel,
} from "./services/export.service";
@Component({
  components: {
    SearchFilterBarCpt: () => Promise.resolve(SearchFilterBarCpt),
  },
})
export default class CreateExportView extends AppVue {
  results = 0;
  projectTab = true;
  companyTab = false;
  fields: ExportFieldModel[] = [];
  model = new ExportConfigurationModel();
  pfields: string[] = ["ProjectId", "ProjectHeading", "ProjectSiteCounty", "ProjectSiteCountry"];
  cfields: string[] = [];
  downloadLoading = false;
  searchLoading = false;
  searchPreview = new VerifyExportModel();
  $refs: {
    treeProject: HTMLFormElement;
    treeCompany: HTMLFormElement;
  };
  get companyFields() {
    return this.fields.filter((x) => x.entity === "Company").sort((a, b) => a.order - b.order);
  }
  get projectFields() {
    return this.fields.filter((x) => x.entity === "Project").sort((a, b) => a.order - b.order);
  }
  get treeCompanyFields() {
    return this.treeDataTransform("Company");
  }
  get treeProjectFields() {
    return this.treeDataTransform("Project");
  }
  treeDataTransform(type: string) {
    const f = this.fields.filter((x) => x.entity === type).sort((a, b) => a.order - b.order);
    const groupBy = (xs: any[], key: string) =>
      xs.reduce((rv, x) => {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    const grouped = groupBy(f, "category");
    const noCategoryMeansRoot = true;
    let roots: any[] = [];
    const res2 = Object.entries(grouped).map(([key, value]) => {
      const v = value as ExportFieldModel[];
      if (key === "" && noCategoryMeansRoot) {
        roots = v.map((y) => ({
          label: y.displayName,
          data: y.field,
          order: y.order,
        }));
        return null;
      }
      return {
        order: v[0].order,
        data: key,
        label: key || "Basic",
        children: v.map((y) => ({
          label: y.displayName,
          data: y.field,
          order: y.order,
        })),
      };
    }) as any[];
    const output = roots.concat(res2.filter((x) => x !== null)).sort((a, b) => {
      if (!!a.children) {
        a.children = a.children.sort((c: ExportFieldModel, d: ExportFieldModel) => c.order - d.order);
      }
      return a.order - b.order;
    });
    return output;
  }
  companyTreeCheck(node: any, satus: any) {
    this.cfields = this.$refs.treeCompany.getCheckedKeys(true);
  }
  projectTreeCheck(node: any, satus: any) {
    this.pfields = this.$refs.treeProject.getCheckedKeys(true);
  }
  updateTree() {
    this.$nextTick(() => {
      try {
        this.$refs.treeCompany.setCheckedKeys(this.cfields, true);
        this.$refs.treeProject.setCheckedKeys(this.pfields, true);
      } catch {}
    });
  }
  queryUpdated() {
    this.searchLoading = true;
    this.$nextTick(() => {
      this.previewRequest();
      this.searchLoading = false;
    });
  }
  filterUpdated(filters: IUIFilters) {
    this.model.SearchModel.fields.length = 0;
    const results: Field[] = SearchService.buildProjectFilter(
      filters.trackingFilter,
      filters.geoRange,
      filters.value.min,
      filters.value.max,
      filters.stage,
      filters.categories,
      [
        filters.dateUpdated,
        filters.dateCreated,
        filters.tenderDeadlineDate,
        filters.finishDate,
        filters.projectStartDate,
        filters.applicationDate,
        filters.decisionDate,
      ],
      filters.counties,
      filters.isSelfBuild,
      filters.roles,
      filters.polyline,
      filters.fundingType,
      filters.constructionType,
      filters.planningAuthority,
      filters.materials,
      filters.floorArea,
      filters.numberOfParkingSpaces,
      filters.siteArea,
      filters.numberOfStoreys,
      filters.numberOfUnits,
      filters.applicationType,
      filters.isFramework,
      filters.appealStatusList,
      filters.publicTag,
    );
    results.forEach((x) => this.model.SearchModel.fields.push(x));
    this.$nextTick(() => {
      this.previewRequest();
      this.searchLoading = false;
    });
  }
  async created() {
    this.fields = await ExportService.getExportFields();
    this.updateTree();
  }
  async previewRequest() {
    this.model.OutputSettings.ColumnList = [
      ...(this.companyTab ? this.cfields : []),
      ...(this.projectTab ? this.pfields : []),
    ];
    this.searchPreview = await ExportService.previewExport(this.model);
  }
  async sendRequest() {
    this.model.OutputSettings.ColumnList = [
      ...(this.companyTab ? this.cfields : []),
      ...(this.projectTab ? this.pfields : []),
    ];

    try {
      this.downloadLoading = true;
      const filename = `Export.xls`;
      const result = await ExportService.runExport(this.model);
      const url = window.URL.createObjectURL(new Blob([result]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      this.$notify({
        title: "Download completed!",
        type: "success",
        message: `${filename} downloaded successfully`,
      });
    } finally {
      this.downloadLoading = false;
    }
  }

  async mounted() {}
}
