









import { Component, Vue, Prop } from "vue-property-decorator";

import {} from "@/core/services";
import {} from "@/core/components";

import AppVue from "@/AppVue.vue";

@Component({
  components: {},
})
export default class WordpressEmbedCpt extends AppVue {
  @Prop({ default: "https://www.cisireland.com/support-section-2/" })
  url: string;
  get urlWithRnd() {
    return `${this.url}?rand=${Math.round(Math.random() * 10000000)}`;
  }
  frameHeight: string = "100px";
  receiveMessage(e: any) {
    const messageStr = e.data;
    if (e.origin != "https://www.cisireland.com") {
      return;
    }
    const message = JSON.parse(messageStr);
    if (message.height) {
      this.frameHeight = message.height + "px";
    }
  }
  async mounted() {
    window.addEventListener("message", this.receiveMessage);
  }
  async created() {}

  async beforeDestroy() {
    window.removeEventListener("message", this.receiveMessage);
  }
}
