import { ExportListView } from ".";
const rootPath: string = "/exports";

export const routes = [
    {
        path: `${rootPath}`,
        name: "exports",
        component: ExportListView,
        meta: { layout: "default" },
    },
];
