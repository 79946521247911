





import { Component, Vue } from "vue-property-decorator";
import {} from "@/core/services";
import {} from "@/core/components";
import {} from "@/core/models";

import AppVue from "@/AppVue.vue";
import WordpressEmbedCpt from "./WordpressEmbedCpt.vue";

@Component({
  components: {
    WordpressEmbedCpt: () => Promise.resolve(WordpressEmbedCpt),
  },
})
export default class EmbedView extends AppVue {
  async created() {}

  async mounted() {}
}
