




































































import { Component, Vue, Prop } from "vue-property-decorator";

import {} from "@/core/services";
import { SectorIconCpt } from "@/core/components";

import AppVue from "@/AppVue.vue";
import { LocationObject } from "./MapView.vue";
import { ProjectLabeledSectionCpt } from "../project";
import { LabeledCompanyCpt } from "../search";
import { roles as Roles, roleFlag } from "@/core/constants";

@Component({
  components: {
    SectorIconCpt,
    LabeledCompanyCpt: () => Promise.resolve(LabeledCompanyCpt),
    ProjectLabeledSectionCpt: () => Promise.resolve(ProjectLabeledSectionCpt),
  },
})
export default class PopupcontentCpt extends AppVue {
  @Prop({ default: {} }) marker: LocationObject;
  @Prop({ default: {} }) router: any;
  roles = { icons: roleFlag, ...Roles };

  get model() {
    return this.marker.data;
  }

  get hasCategory() {
    return !!this.marker.data && !!this.marker.data.categories && this.marker.data.categories.length > 0;
  }

  goToProject() {
    this.router.push({
      name: "Project",
      params: { projectId: this.model.projectId },
    });
  }

  async mounted() {}
}
