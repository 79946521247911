import { ColorFormat } from "element-ui/types/color-picker";

export default (drawLayer: any, color: string, fillOpacity: number, borderOpacity: number = 0.5) => ({
    position: "topleft",
    edit: {
        featureGroup: drawLayer,
        edit: false,
        remove: false,
    },
    draw: {
        polygon: false,
        rectangle: {
            shapeOptions: {
                stroke: true,
                color,
                weight: 4,
                opacity: borderOpacity,
                fill: true,
                fillOpacity,
            },
        },
        polyline: {
            shapeOptions: {
                stroke: true,
                color,
                weight: 4,
                opacity: borderOpacity,
                fill: true,
                fillOpacity,
            },
        },
        marker: false,
        circlemarker: false,
        circle: {
            shapeOptions: {
                stroke: true,
                color,
                weight: 4,
                opacity: borderOpacity,
                fill: true,
                fillOpacity,
            },
            showRadius: true,
            metric: true,
        },
    },
} as L.Control.DrawConstructorOptions);
