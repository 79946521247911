
















































import L, {
  CircleMarker,
  CircleMarkerOptions,
  FeatureGroup,
  LatLng,
  LatLngExpression,
  LatLngLiteral,
  PolylineOptions,
} from "leaflet";
import "leaflet-draw";

import "leaflet-draw/dist/leaflet.draw.css";
import { MarkerClusterGroup } from "leaflet.markercluster";
import { Icon } from "leaflet";
interface IDefault extends Icon.Default {
  _getIconUrl: any;
}
delete (Icon.Default.prototype as IDefault)._getIconUrl;

Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});
import { greenIcon, blueIcon, orangeIcon, IconFactory } from "./markers";
import { Component, Vue, Prop } from "vue-property-decorator";
import { LMap, LTileLayer, LMarker, LCircle, LPopup } from "vue2-leaflet";
import Vue2LeafletMarkerCluster from "vue2-leaflet-markercluster/dist/Vue2LeafletMarkercluster";

import { IconService } from "@/core/services";

import AppVue from "@/AppVue.vue";
import {
  CompanySearchResultModel,
  ProjectModel,
  ProjectSearchResultModel,
} from "../../core/models";
import MapPopupContent from "./MapPopupContent.vue";
import { default as DrawBarConfigFactory } from "./DrawBarConfig";

interface IMapElement extends Element {
  mapObject: any;
}
export class LocationObject {
  resultId: number;
  latLong: LatLngExpression;
  data: any;
  zoom: number;
  type?: string;
  pin: boolean;
  text: string;
}
export const MapEvents = {
  Focus: "mapFocus",
  Refresh: "mapRefreshSize",
  CloseAllPopups: "mapCloseAllPopups",
  Reset: "mapReset",
  AddCompanyMarker: "mapAddMarker",
  AddCompanyMarkerCollection: "mapAddCompanyMarkerCollection",
  AddMarkerCollection: "mapAddMarkerCollection",
  AddMarkerCollectionNoFly: "mapAddMarkerCollectionNoFly",
  Clear: "mapClear",
  Center: "mapCenter",
  DrawRectangle: "drawRectangle",
  DrawCircle: "drawCircle",
  DrawPolyline: "drawPolyline",
  DrawCircleFromUserInput: "drawCircleFromUserInput",
  DrawPolylineFromUserInput: "drawPolylineFromUserInput",
  ClearCircleLayer: "clearCircleLayer",
  ClearDrawLayer: "clearDrawLayer",
};

class TurnstoneMarker extends L.Marker<any> {
  markerId: number;
}

interface IMapObject {
  mapObject: IPopupRef;
}
interface IPopupRef {
  openPopup(): void;
}

@Component({
  components: {
    LMap,
    LMarker,
    LTileLayer,
    LPopup,
    Vue2LeafletMarkerCluster: () => Promise.resolve(Vue2LeafletMarkerCluster),
  },
})
export default class MapView extends AppVue {
  @Prop({ default: false }) loading: boolean;
  @Prop({ default: true }) toolbar: boolean;
  @Prop({ default: false }) disableAutoCenter: boolean;
  @Prop({ default: true }) showOtherButtons: boolean;
  @Prop({ default: () => [] }) initialPosition: number[];

  popupContent = Vue.extend(MapPopupContent);
  Dublin = [53.3498, -6.2603];
  latestPosition: LatLngExpression = {
    lat: this.Dublin[0],
    lng: this.Dublin[1],
  };
  latestZoom = 8;
  mapLayer: any = {};
  clusterOptions: any = {};
  lastCircle: any = null;
  zoomOffset: number = 4;
  zoom = 5;
  map: any = {};
  layerOptions = {
    tileSize: 512,
    zoomOffset: -1,
  };
  gettingLocation = false;
  attribution = `&copy; <a href="https://www.mapbox.com/feedback/">Mapbox</a> &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>
  `;
  user = "cis20";
  isSatellite = false;
  satelliteStyle = "ckk2q07oq3oh917js2wykuya2";
  style = "ckeimyf5725u219qex1vcg26k";
  accesstoken =
    "pk.eyJ1IjoiY2lzMjAiLCJhIjoiY2tjYW5nYnprMXd4djMybG1tZDlmendlbCJ9.Q2Z1eAOU3BfFKhg_IseQ7g";
  api = "api.mapbox.com/styles/v1";
  markers: TurnstoneMarker[] = [];
  companyMarkers: TurnstoneMarker[] = [];
  focus = [] as number[][];
  layers = {
    clusteredLayer: new MarkerClusterGroup(),
    companyLayer: new MarkerClusterGroup(),
    drawLayer: new L.FeatureGroup(),
    positionLayer: new L.Layer(),
  };
  drawToolbarConfig = DrawBarConfigFactory(
    this.layers.drawLayer,
    "#fb6d13",
    0.1,
  );
  get url() {
    if (this.isSatellite) {
      return `https://${this.api}/${this.user}/${this.satelliteStyle}/tiles/{z}/{x}/{y}?access_token=${this.accesstoken}`;
    }
    return `https://${this.api}/${this.user}/${this.style}/tiles/{z}/{x}/{y}?access_token=${this.accesstoken}`;
  }
  async created() {}
  boundsUpdated(ev: any) {
    this.$emit("boundsChange", ev);
  }
  zoomChange(ev: any) {
    this.zoom = ev;
    this.$emit("zoomChange", ev);
  }
  searchHere() {
    this.$emit("searchHere");
  }
  myLocation() {
    this.refreshLocation();
    this.$emit("centerMyLocation");
  }
  refreshLocation() {
    if (!("geolocation" in navigator)) {
      this.latestPosition = {
        lat: this.Dublin[0],
        lng: this.Dublin[1],
      };
      return;
    }
    this.gettingLocation = true;
    navigator.geolocation.getCurrentPosition(
      (pos) => {
        this.gettingLocation = false;
        this.latestPosition = {
          lat: pos.coords.latitude,
          lng: pos.coords.longitude,
        };
        this.layers.positionLayer.removeFrom(this.map);
        this.layers.positionLayer = L.marker(this.latestPosition);
        this.layers.positionLayer.addTo(this.map);
      },
      (err) => {
        // this.latestPosition = {
        //   lat: this.Dublin[0],
        //   lng: this.Dublin[1],

        // };
        this.layers.positionLayer.removeFrom(this.map);

        this.gettingLocation = false;
      },
    );
  }
  resetDrawLayer() {
    this.map.removeLayer(this.layers.drawLayer);
    this.layers.drawLayer = new FeatureGroup();
    this.layers.drawLayer.addTo(this.map);
  }
  DrawShape(event: L.Layer) {
    this.resetDrawLayer();
    this.layers.drawLayer.addLayer(event);
  }
  DrawPolylineFromUserInput(input: LatLng[]) {
    this.resetDrawLayer();
    input.push(input[0]);
    const l = L.polyline(
      input,
      this.drawToolbarConfig.draw?.polyline as PolylineOptions,
    );
    this.layers.drawLayer.addLayer(l);
  }
  DrawCircleFromUserInput(input: any) {
    this.resetDrawLayer();
    const l = L.circle(
      input.center,
      Number(input.radius) * 1000,
      this.drawToolbarConfig.draw?.circle as CircleMarkerOptions,
    );
    const center = L.marker(input.center);
    this.layers.drawLayer.addLayer(l);
    this.layers.drawLayer.addLayer(center);
  }
  doSomethingOnReady() {
    const mapObject = this.$refs.myMap as IMapElement;
    this.map = mapObject.mapObject;
    if (this.toolbar) {
      const drawControl = new L.Control.Draw(this.drawToolbarConfig);
      this.map.addControl(drawControl);
    }
    this.attachMapEvents();
    this.$nextTick(() => {
      this.Reset();
    });
  }
  attachMapEvents() {
    this.map.on(L.Draw.Event.CREATED, this.onDrawingCompleted);
    this.map.on(L.Draw.Event.DRAWSTART, this.onDrawingStarted);
  }
  detachMapEvents() {
    this.map.off(L.Draw.Event.CREATED, this.onDrawingCompleted);
    this.map.off(L.Draw.Event.DRAWSTART, this.onDrawingStarted);
  }
  onDrawingStarted(event: any) {
    this.resetDrawLayer();
    this.layers.drawLayer.addTo(this.map);
  }
  onDrawingCompleted(event: any) {
    const eventType: string = event.layerType;
    // this is to notify all connected elements that the circle layer is
    // not valid anymore
    switch (eventType) {
      case "polyline":
        this.$eventHub.$emit(MapEvents.ClearCircleLayer);
        this.$eventHub.$emit(MapEvents.DrawPolyline, event.layer);
        break;
      case "rectangle":
        this.$eventHub.$emit(MapEvents.ClearCircleLayer);
        this.$eventHub.$emit(MapEvents.DrawRectangle, event.layer);
        break;
      case "circle":
        this.$eventHub.$emit(MapEvents.ClearDrawLayer);
        this.$eventHub.$emit(MapEvents.DrawCircle, event.layer);
        break;
      case "polygon":
        break;
      case "marker":
        break;
    }
  }

  switchLayer() {
    this.isSatellite = !this.isSatellite;
  }

  mounted() {
    this.$eventHub.$on(MapEvents.Refresh, this.Refresh);
    this.$eventHub.$on(MapEvents.CloseAllPopups, this.closeAllPopups);
    this.$eventHub.$on(MapEvents.Focus, this.Focus);
    this.$eventHub.$on(MapEvents.Reset, this.Reset);
    this.$eventHub.$on(MapEvents.AddCompanyMarker, this.AddCompanyMarker);
    this.$eventHub.$on(
      MapEvents.AddCompanyMarkerCollection,
      this.AddCompanyMarkerCollection,
    );
    this.$eventHub.$on(MapEvents.AddMarkerCollection, this.AddMarkerCollection);
    this.$eventHub.$on(
      MapEvents.AddMarkerCollectionNoFly,
      this.AddMarkerCollectionNoFly,
    );
    this.$eventHub.$on(MapEvents.Clear, this.Clear);
    this.$eventHub.$on(MapEvents.Center, this.Center);

    this.$eventHub.$on(
      MapEvents.DrawPolylineFromUserInput,
      this.DrawPolylineFromUserInput,
    );
    this.$eventHub.$on(
      MapEvents.DrawCircleFromUserInput,
      this.DrawCircleFromUserInput,
    );
    this.$eventHub.$on(MapEvents.ClearDrawLayer, this.resetDrawLayer);
    this.$eventHub.$on(MapEvents.DrawPolyline, this.DrawShape);
    this.$eventHub.$on(MapEvents.DrawRectangle, this.DrawShape);
    this.$eventHub.$on(MapEvents.DrawCircle, this.DrawShape);

    this.trackEvent("MapLoaded", { name: "MapLoaded" });
    if (!!this.initialPosition && !!this.initialPosition.length) {
      this.latestPosition = {
        lat: this.initialPosition[0],
        lng: this.initialPosition[1],
      };
    }
  }
  destroyed() {
    this.detachMapEvents();
    this.$eventHub.$off(MapEvents.CloseAllPopups, this.closeAllPopups);
    this.$eventHub.$off(MapEvents.Refresh, this.Refresh);
    this.$eventHub.$off(MapEvents.Focus, this.Focus);
    this.$eventHub.$off(MapEvents.Reset, this.Reset);
    this.$eventHub.$off(MapEvents.AddCompanyMarker, this.AddCompanyMarker);
    this.$eventHub.$off(
      MapEvents.AddMarkerCollectionNoFly,
      this.AddMarkerCollectionNoFly,
    );
    this.$eventHub.$off(
      MapEvents.AddCompanyMarkerCollection,
      this.AddCompanyMarkerCollection,
    );
    this.$eventHub.$off(
      MapEvents.AddMarkerCollection,
      this.AddMarkerCollection,
    );
    this.$eventHub.$off(MapEvents.Clear, this.Clear);
    this.$eventHub.$off(MapEvents.Center, this.Center);
    this.$eventHub.$off(
      MapEvents.DrawPolylineFromUserInput,
      this.DrawPolylineFromUserInput,
    );

    this.$eventHub.$off(
      MapEvents.DrawCircleFromUserInput,
      this.DrawCircleFromUserInput,
    );
    this.$eventHub.$off(MapEvents.ClearDrawLayer, this.resetDrawLayer);

    this.$eventHub.$off(MapEvents.DrawPolyline, this.DrawShape);
    this.$eventHub.$off(MapEvents.DrawRectangle, this.DrawShape);
    this.$eventHub.$off(MapEvents.DrawCircle, this.DrawShape);
  }

  Reset() {
    // should clear the markers
    this.Clear();
    if (!this.disableAutoCenter) {
      this.Center();
    }
  }
  Center() {
    this.closeAllPopups();
    if (this.layers.clusteredLayer) {
      const bounds = this.layers.clusteredLayer.getBounds();
      if (!!bounds._northEast) {
        this.map.fitBounds(bounds);
      } else {
        this.map.setView(this.latestPosition, this.latestZoom);
      }
    } else {
      this.map.setView(this.latestPosition, this.latestZoom);
    }
    this.Refresh();
  }
  Clear() {
    this.markers.length = 0;
    this.companyMarkers.length = 0;
    try {
      if (this.lastCircle && this.map) {
        this.map.removeLayer(this.lastCircle);
      }
      if (this.layers.companyLayer && this.map) {
        this.map.removeLayer(this.layers.companyLayer);
      }
      if (this.layers.clusteredLayer && this.map) {
        this.map.removeLayer(this.layers.clusteredLayer);
      }
    } catch (err) {
      // we catch the error because the leaflet library doesn't
    }
    this.layers.clusteredLayer = new MarkerClusterGroup();
    this.layers.companyLayer = new MarkerClusterGroup();
  }

  convertLatLong(location: LocationObject) {
    if (Array.isArray(location.latLong)) {
      return location.latLong;
    } else {
      const lit = location.latLong as LatLngLiteral;
      if (lit && lit.lat) {
        return [lit.lat, lit.lng];
      }
    }
    return null;
  }
  Refresh() {
    this.$nextTick(() => {
      try {
        this.map.invalidateSize();
      } catch {}
    });
  }
  AddPopup(marker: TurnstoneMarker, loc: LocationObject) {
    const popupId = `vue-popup-${loc.resultId}`;
    const popup = new L.Popup(
      { className: "turnstone-popup" },
      marker,
    ).setContent(`<div id="${popupId}"></div>`);
    marker.bindPopup(popup);
    const popupHandler = (ev: any) => {
      const instance = new this.popupContent({
        propsData: {
          marker: loc,
          router: this.$router,
        },
      }).$mount(`#${popupId}`);
      popup.on("close", () => {
        instance.$destroy();
      });
    };

    marker.on("popupopen", popupHandler);
    marker.on("mouseover", () => {
      if (!marker.isPopupOpen()) {
        marker.openPopup();
      }
    });
  }

  CreateMarker(location: LocationObject, type: any) {
    const loca = location.data as any;
    let iconClass = "cis-icon-factory";

    if (loca && loca.categories) {
      iconClass = IconService.getIconByCategoryName(loca.categories[0]);
    }
    if (loca && loca.defaultRole) {
      iconClass = IconService.getIconByRoleName(loca.defaultRole);
      if (!iconClass) {
        iconClass = IconService.getIconByRoleName("Defult");
      }
    }
    if (type === "orange" || type === "company") {
      return L.marker(location.latLong, {
        icon: IconFactory.getCompanyIconMarker(iconClass),
      }) as TurnstoneMarker;
    }
    return L.marker(location.latLong, {
      icon: IconFactory.getIconForclass(iconClass),
    }) as TurnstoneMarker;
  }

  AddCompanyMarker(location: LocationObject, openPopup: boolean = false) {
    this.$nextTick(() => {
      if (!!this.layers.companyLayer && this.map) {
        this.map.removeLayer(this.layers.companyLayer);
      }
      const marker = this.CreateMarker(location, location.type);
      if (!marker) {
        return null;
      }
      this.layers.companyLayer = marker;
      this.map.addLayer(this.layers.companyLayer);
      this.companyMarkers.push(marker);
      setTimeout(() => {
        this.map.setZoom(location.zoom || 15);
        this.map.panTo(location.latLong);
        this.latestPosition = location.latLong;
        this.latestZoom = location.zoom;
      });
    });
  }
  AddCompanyMarkerCollection(
    locationList: LocationObject[],
    flyTo: boolean = true,
  ) {
    const newCompanyMarkers: any[] = [];
    const mGroup = this.layers.companyLayer;
    this.map.removeLayer(mGroup);
    if (!locationList) {
      return;
    }

    locationList.forEach((loc) => {
      const marker = this.CreateMarker(loc, loc.type);
      if (!marker) {
        return;
      }
      if (this.companyMarkers.find((m) => m.markerId === loc.resultId)) {
        return;
      }
      marker.markerId = loc.resultId;
      this.companyMarkers.push(marker);
      newCompanyMarkers.push(marker);
      this.AddPopup(marker, loc);

      marker.on("click", () => this.$eventHub.$emit("MarkerClicked", loc));
      marker.on("click", () =>
        this.trackEvent("MarkerClick", {
          category: "MapEvent",
          label: "MarkerClick",
          value: loc.resultId,
        }),
      );
    });
    this.$nextTick(() => {
      mGroup.addLayers(newCompanyMarkers);
      this.map.addLayer(mGroup);
      setTimeout(() => {
        this.fitToCompanyMarkers();
        try {
          this.map.invalidateSize();
        } catch {}
      });
    });
  }
  AddMarkerCollectionNoFly(locationList: LocationObject[]) {
    return this.AddMarkerCollection(locationList, false);
  }
  AddMarkerCollection(locationList: LocationObject[], flyTo: boolean = true) {
    const newMarkers: any[] = [];
    this.$nextTick(() => {
      const mGroup = this.layers.clusteredLayer;
      if (!locationList) {
        return;
      }
      locationList.forEach((loc) => {
        const marker = this.CreateMarker(loc, loc.type);
        if (!marker) {
          return;
        }
        if (this.markers.find((m) => m.markerId === loc.resultId)) {
          return;
        }
        marker.markerId = loc.resultId;
        this.markers.push(marker);
        newMarkers.push(marker);
        this.AddPopup(marker, loc);

        marker.on("click", () => this.$eventHub.$emit("MarkerClicked", loc));
        marker.on("click", () =>
          this.trackEvent("MarkerClick", {
            category: "MapEvent",
            label: "MarkerClick",
            value: loc.resultId,
          }),
        );
      });
      mGroup.addLayers(newMarkers);
      setTimeout(() => {
        this.map.addLayer(mGroup);
        if (flyTo) {
          this.fitToProjectMarkers();
        }
        try {
          this.map.invalidateSize();
        } catch {}
      });
    });
  }
  fitToMarkers(bounds: any) {
    this.$nextTick(() => {
      const options = {
        duration: 1,
        animate: true,
        minZoom: 5,
        maxZoom: 11,
      };
      this.map.flyToBounds(bounds, options);
    });
  }

  fitToCompanyMarkers() {
    try {
      if (this.map._size.x > 0 && this.map._size.y > 0) {
        if (this.layers.companyLayer) {
          const bounds = this.layers.companyLayer.getBounds();
          if (!!bounds._northEast) {
            this.fitToMarkers(bounds);
          }
        }
      }
    } finally {
    }
  }
  fitToProjectMarkers() {
    try {
      if (this.map._size.x > 0 && this.map._size.y > 0) {
        if (this.layers.clusteredLayer) {
          const bounds = this.layers.clusteredLayer.getBounds();
          if (!!bounds._northEast) {
            this.fitToMarkers(bounds);
          }
        }
      }
    } finally {
    }
  }
  closeAllPopups() {
    const totalMarkers = this.markers.concat(this.companyMarkers);

    const p = totalMarkers.filter((x) => x && x.isPopupOpen());
    p.forEach((x) => x.closePopup());
  }
  openPopup(resultId: number) {
    const totalMarkers = this.markers.concat(this.companyMarkers);
    const marker = totalMarkers.find(
      (x: TurnstoneMarker) => x.markerId === resultId,
    );
    this.$nextTick(() => {
      try {
        if (marker) {
          marker.openPopup();
        }
      } finally {
      }
    });
  }

  Focus(location: LocationObject) {
    this.closeAllPopups();
    if (this.lastCircle && this.map) {
      this.map.removeLayer(this.lastCircle);
    }
    const totalMarkers = this.markers.concat(this.companyMarkers);

    const results = totalMarkers.filter((x) => {
      return Number(x.markerId) === Number(location.resultId);
    });
    const res = results[0];
    if (location && location.latLong) {
      this.latestPosition = location.latLong;
      this.latestZoom = location.zoom;
      this.lastCircle = L.circleMarker(location.latLong).addTo(this.map);
      if (!this.disableAutoCenter) {
        this.map.setView(location.latLong, location.zoom);
        try {
          this.map.invalidateSize();
        } catch {}
      }
      this.$nextTick(() => {
        res?.openPopup();
      });
    }
  }
}
