

















































































































































import { Component, Vue } from "vue-property-decorator";
import { IUICompanyFilters, IUIFilters, SearchService } from "@/core/services";
import { FooterCpt } from "@/core/components";
import { SearchFilterSummaryCpt } from "@/modules/search/components";
import { DateRangeFilter, PagedCollection, PagedCollectionFilter, SearchModel } from "@/core/models";

import AppVue from "@/AppVue.vue";
import { ExportService, UserExportModel } from "./services/export.service";
import { CreateCompanyExportCpt, CreateExportCpt, ExportSavedSearchesCpt, ExportSummaryCpt } from "./components";

@Component({
  components: {
    SearchFilterSummaryCpt: () => Promise.resolve(SearchFilterSummaryCpt),
    CreateExportCpt: () => Promise.resolve(CreateExportCpt),
    CreateCompanyExportCpt: () => Promise.resolve(CreateCompanyExportCpt),
    ExportSummaryCpt: () => Promise.resolve(ExportSummaryCpt),
    ExportSavedSearchesCpt: () => Promise.resolve(ExportSavedSearchesCpt),
    FooterCpt: () => Promise.resolve(FooterCpt),
  },
})
export default class ExportListView extends AppVue {
  model: PagedCollection<UserExportModel> = new PagedCollection<UserExportModel>();
  showExportDialog = false;
  activeFilters: IUIFilters | IUICompanyFilters = SearchService.defaultFilter();
  showExportType = "Project";
  async created() {
    this.refreshModel();
  }

  async refreshModel() {
    this.model = await ExportService.list(1);
  }

  async onDownloadStarted(status: string) {
    await new Promise((res) => setTimeout(res, 1000));
    this.refreshModel();
    this.showExportDialog = false;
  }

  async download(userSearchExportId: number) {
    const result = await ExportService.getExportFile(userSearchExportId);
    const url = window.URL.createObjectURL(new Blob([result]));
    const link = document.createElement("a");
    let extension = "xls";
    if (result.type !== "application/xls") {
      extension = "pdf";
    }
    const filename = `Export ${userSearchExportId}.${extension}`;
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    this.$notify({
      title: "Download completed!",
      type: "success",
      message: `${filename} downloaded successfully`,
    });
  }

  run(searchModel: SearchModel, type: string) {
    if (searchModel.searchType === 1 || type === "Company") {
      this.activeFilters = SearchService.convertCompanySearchModelToFilters(searchModel);
      this.showExportType = "Company";
    } else {
      this.activeFilters = SearchService.convertProjectSearchModelToFilters(searchModel);
      this.showExportType = "Project";
    }
    this.showExportDialog = true;
  }

  mounted() {
    super.updateTitle("Export");
  }
}
